<template>
    <fhiry-page class="business" :banners="businessBanners">
        <div class="business-wrapper">
            <div class="business-inner-warpper">
                <nav class="buiness-nav">
                    <fhiry-nav-bar :navs="currentNav == '' ? [] : currentNav.children"/>
                </nav>
                <div class="buiness-content">
                    <router-view />
                </div>
            </div>
        </div>
    </fhiry-page>
</template>

<script>
    // 业务领域
    // 组件
    import FhiryPage from '@/components/FhiryPage';
    import FhiryNavBar from '@/components/FhiryNavBar';

    // vuex
    import { mapState, mapGetters } from 'vuex';

    export default {
        name: 'business',
        computed: {
            ...mapState([
                'businessBanners'
            ]),
            ...mapGetters([
                'currentNav'
            ])
        },
        components: {
            FhiryPage,
            FhiryNavBar
        }
    }
</script>

<style lang="less" scoped>
    @import '~@/assets/less/common.less';

    .business {
        .business-wrapper {
            padding: 30px 0;
            .business-inner-warpper {
                display: flex;
                align-items: flex-start;
                margin: 0 auto;
                width: @limit-width;
                .buiness-content, .buiness-nav {
                    background-color: #FFF;
                }
                .buiness-nav {
                    flex: calc(~'24% - 20px');
                    margin-right: 20px;
                }
                .buiness-content {
                    flex: 76%;
                }
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .business {
            .business-wrapper {
                padding: 0.53333rem 0.26666rem;
                .business-inner-warpper {
                    width: 100%;
                    .buiness-nav {
                        display: none;
                    }
                }
            }
        }
    }
</style>