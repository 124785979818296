<template>
    <div class="fhiry-nav-bar">
        <div
            class="nav-item-box"
            v-for="(nav, i) in navs"
            :key="i"
        >
            <h5 class="nav-bundle-title">
                {{ nav.text }}
            </h5>
            <ul
                class="nav-bundle-list"
                v-show="nav.children != void 0 && nav.children.length > 0"
            >
                <li
                    v-for="(n, idx) in nav.children"
                    :key="idx"
                    :class="{'active': n.path == activeNav}"
                    @click="changeNav(n)"
                >
                    {{ n.text }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    // fhiry导航
    export default {
        name: 'fhiry-nav-bar',
        props: {
            navs: {
                type: Array,
                default () {
                    return [];
                }
            }
        },
        methods: {
            changeNav (nav) {
                this.$router.push({
                    path: nav.path
                })
            }
        },
        computed: {
            activeNav () {
                return this.$route.path;
            }
        },
    }
</script>

<style lang="less">
    @import '~@/assets/less/common.less';

    .fhiry-nav-bar {
        .nav-item-box {
            padding-bottom: 10px;
            border-bottom: 1px dotted #999;
            &:last-child {
                border: 0;
            }
            .nav-bundle-title {
                padding: 10px 22px;
                color: #777;
                font-size: 12px;
            }
            .nav-bundle-list {
                li {
                    position: relative;
                    padding: 10px 22px;
                    font-size: 14px;
                    cursor: pointer;
                    &:before {
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        height: 0px;
                        width: 2px;
                        transition: height 0.3s;
                        // background-color: transparent;
                        background-color: @theme-color;
                        content: '';
                    }
                    &:hover, &.active {
                        background-color: #f6f6f6;
                        &:before {
                            height: 100%;
                            // background-color: @theme-color;
                        }
                    }
                }
            }
        }
    }
</style>